<template>
	<div class="blog-drawer-item">
		<div @click="$emit('click', 'edit')">
			<p
				class="blog-drawer-item__title z-body"
				data-qa="blog-label-posttitle"
			>
				{{ title }}
			</p>
			<p class="blog-drawer-item__description z-body-small">
				{{ description }}
			</p>
			<div class="blog-drawer-item__footer z-subheading-small">
				<p class="blog-drawer-item__footer-type">
					{{ isDraft ? $t('common.draft') : $t('common.published') }}
				</p>
				<p class="blog-drawer-item__footer-date">
					{{ $options.sliceTimeFromISODate(date) }}
				</p>
				<ZyroPopup
					class="popup"
					:show-close-button="false"
					:offset="popupOffset"
					:on-toggle="handlePopupOpen"
				>
					<template #trigger>
						<ZyroButton
							icon="dots"
							:title="$t('common.moreSettings')"
							data-testId="popup-trigger"
							data-qa="blog-btn-postsettings"
							class="blog-drawer-item__settings-button"
						/>
					</template>
					<template #default="popup">
						<ul
							class="popup-content"
							@click.stop
						>
							<li
								v-for="button in settingsButtons"
								:key="button.id"
							>
								<ZyroButton
									v-qa="`trigger-button-${button.id}`"
									theme="plain"
									:icon-left="button.icon"
									:title="button.title"
									class="popup-content__button"
									:class="buttonClass(button.id)"
									text-class="z-body-small"
									@click="handlePostClick(popup, button.id)"
								>
									{{ button.text }}
								</ZyroButton>
							</li>
						</ul>
					</template>
				</ZyroPopup>
			</div>
		</div>
	</div>
</template>

<script>
import { sliceTimeFromISODate } from '@/utils/date';

export default {
	sliceTimeFromISODate,
	props: {
		title: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		date: {
			type: String,
			default: '',
		},
		isDraft: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return { popupOpensToTop: false };
	},
	computed: {
		popupOffset() {
			return this.popupOpensToTop ? {
				bottom: 85,
				right: 13,
			} : {
				top: 22,
				right: 13,
			};
		},
		settingsButtons() {
			return [
				{
					id: 'edit',
					icon: 'edit',
					title: this.$t('common.edit'),
					text: this.$t('common.edit'),
				},
				{
					id: 'duplicate',
					icon: 'duplicate',
					title: this.$t('common.duplicate'),
					text: this.$t('common.duplicate'),
				},
				{
					id: 'delete',
					icon: 'trash',
					title: this.$t('common.delete'),
					text: this.$t('common.delete'),
				},
				{
					id: 'settings',
					icon: 'settings',
					title: this.$t('common.postSettings'),
					text: this.$t('common.postSettings'),
				},
				{
					id: 'publish',
					icon: this.isDraft ? 'publish' : 'publish-unpublish',
					title: this.isDraft ? this.$t('common.publish') : this.$t('common.unpublish'),
					text: this.isDraft ? this.$t('common.publish') : this.$t('common.unpublish'),
				},
			];
		},
	},
	methods: {
		/*
		 *TODO:
		 * Research how to solve this problem differently.
		 * This is repeated in multiple places and doesn't look like a very viable solution.
		 */
		handlePopupOpen(isOpen, popupTriggerPosition) {
			if (!isOpen) {
				return;
			}

			const {
				height: scrollAreaHeight,
				top: scrollAreaTop,
			} = this.$parent.$parent.$refs.items.getBoundingClientRect();

			/*
			 * By default, popup should be opened on the bottom of the item,
			 * unless it's not enough space for popup to fit.
			 * Example:
			 * Open popup of the last item in scroll and it won't be visible, you'd have to scroll down
			 *
			 * Approx. reserved space for popup is 250
			 *
			 */
			this.popupOpensToTop = popupTriggerPosition.top - scrollAreaTop + 250 > scrollAreaHeight;
		},
		buttonClass(id) {
			if (id !== 'publish') {
				return '';
			}

			return `popup-content__button${this.isDraft ? '-publish' : '-unpublish'}`;
		},
		handlePostClick(popup, buttonId) {
			popup.toggle();
			this.$emit('click', buttonId);
		},
	},
};
</script>

<style lang="scss" scoped>
.blog-drawer-item {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 124px;
	padding: 14px 24px 8px 16px;
	margin-bottom: 8px;
	cursor: pointer;
	border: 1px solid $grey-200;
	border-radius: $border-radius-small;

	&__footer {
		display: flex;
		flex-direction: row;
		margin-top: auto;
		margin-bottom: 4px;

		&-date {
			color: $grey-600;
		}

		&-type {
			margin-right: 8px;
		}
	}

	&__title {
		margin-bottom: 6px;
	}

	&__description {
		margin-bottom: 8px;
		color: $grey-800;
	}

	&__settings-button:not(.s) {
		position: absolute;
		top: 8px;
		right: 0;
		overflow: hidden;
	}
}

.popup-content {
	overflow: hidden;
	list-style: none;

	&__button-publish {
		color: $success;
	}

	&__button-unpublish {
		color: $success;
	}
}
</style>
