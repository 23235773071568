<template>
	<ZyroDrawer
		class="drawer"
		data-qa="builder-sidebar-blog"
	>
		<div
			v-if="isWebsiteWithBlogBlock || Object.keys(blogPages).length"
			class="drawer-content"
		>
			<div class="drawer-content__header">
				<div class="drawer-content__header-title z-h5">
					{{ $t('common.blog') }}
				</div>
				<p class="drawer-content__header-description z-body-small">
					{{ $t('builder.blog.blogDrawer.subtitle') }}
				</p>
			</div>
			<div class="drawer-content__tabs">
				<ZyroTabs
					:tabs="$options.tabs"
					:current-tab="currentTab"
					@change="currentTab = $event"
				/>
			</div>
			<div
				ref="items"
				class="drawer-content__list"
			>
				<BlogDrawerItem
					v-for="(post, key) in posts"
					:key="`post-${key}`"
					:title="post.meta.title"
					:date="post.customData.date"
					:description="post.meta.description"
					:is-draft="currentTab.id === 'draft'"
					data-testId="drawer-item"
					data-qa="blog-card-post"
					@click="handleFunctionCall($event, key)"
				/>
			</div>
			<div class="drawer-content__footer">
				<ZyroButton
					theme="outline"
					class="list-button"
					data-testId="add-post-button"
					data-qa="blog-btn-addnewpost"
					icon-left="plus"
					@click="addBlogPost"
				>
					{{ $t('builder.blog.blockBlogListEmptyBlock.addNewPost') }}
				</ZyroButton>
				<ZyroButton
					theme="outline"
					class="list-button"
					:title="$t('builder.blog.blogDrawer.openModal')"
					icon="settings"
					data-qa="blog-btn-settings"
					@click="openModal({ name: 'BlogSettingsModal' })"
				/>
			</div>
		</div>
		<EmptyBlogDrawer
			v-else
			@add-post="addBlogList"
		/>
	</ZyroDrawer>
</template>

<script>
import { nanoid } from 'nanoid';
import {
	mapActions,
	mapGetters,
	mapState,
} from 'vuex';

import BlogDrawerItem from '@/components/builder-controls/blog-drawer/BlogDrawerItem.vue';
import { BLOG_LIST_PAGE_TYPE } from '@/constants';
import i18n from '@/i18n/setup';
import { BLOG_ROUTE } from '@/router';
import {
	mapActionsGui,
	OPEN_MODAL,
} from '@/store/builder/gui';

import EmptyBlogDrawer from './partials/blogDrawer/EmptyBlogDrawer.vue';

const tabs = [
	{
		id: 'draft',
		title: i18n.t('common.draft'),
	},
	{
		id: 'published',
		title: i18n.t('common.published'),
	},
];

export default {
	components: {
		BlogDrawerItem,
		EmptyBlogDrawer,
	},
	data() {
		return { currentTab: this.$options.tabs[1] };
	},
	computed: {
		...mapGetters('pages', [
			'draftBlogPages',
			'publishedBlogPages',
			'blogPages',
		]),
		...mapGetters('blog', ['isWebsiteWithBlogBlock']),
		...mapState('user', ['user']),
		posts() {
			return this.currentTab.id === 'published' ? this.publishedBlogPages : this.draftBlogPages;
		},
	},
	methods: {
		...mapActions('blog', ['toggleBlogPostVisibility']),
		...mapActions('pages', [
			'setCurrentPage',
			'addPage',
			'duplicatePage',
			'removePage',
		]),
		...mapActionsGui({ openModal: OPEN_MODAL }),
		handleFunctionCall(functionName, blogPostId) {
			this[functionName](blogPostId);
		},
		edit(pageId) {
			this.$router.push({
				name: BLOG_ROUTE,
				params: { postId: pageId },
			});
			this.setCurrentPage({
				type: 'blog',
				payload: { pageId },
			});
		},
		settings(blogPostId) {
			this.openModal({
				name: 'BlogPostSettingsModal',
				settings: { blogPostId },
			});
		},
		duplicate(blogPostId) {
			this.duplicatePage({
				type: 'blog',
				payload: { pageId: blogPostId },
			});
		},
		delete(blogPostId) {
			this.removePage({
				type: 'blog',
				payload: { pageId: blogPostId },
			});
		},
		publish(blogPostId) {
			this.toggleBlogPostVisibility(blogPostId);
		},
		addBlogPost() {
			const postId = nanoid();

			this.$router.push({
				name: BLOG_ROUTE,
				params: { postId },
			});
			this.addPage({
				type: 'blog',
				payload: { pageId: postId },
			});
			window.hj('identify', this.user.id, { 'builder.blog.create_new_post': true });
		},
		addBlogList() {
			this.addPage({ type: BLOG_LIST_PAGE_TYPE });
			if (this.user?.id) {
				window.hj('identify', this.user.id, { 'builder.blog.add_blog_page': true });
			}
		},
	},
	tabs,
};
</script>

<style lang="scss" scoped>
.drawer {
	overflow: visible;

	&-content {
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow: hidden;

		&__header {
			flex: 0;
			padding: 24px;
			margin-bottom: 16px;

			&-title {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 8px;
			}

			&-description {
				color: $grey-600;
			}
		}

		&__list {
			flex: 1;
			width: 100%;
			padding: 0 24px;
			overflow-x: hidden;
			overflow-y: auto;
			border-bottom: 1px solid $grey-300;
		}

		&__tabs {
			padding: 0 24px;
		}

		&__footer {
			display: flex;
			justify-content: space-between;
			width: 100%;
			padding: 24px;
			margin-top: auto;
		}
	}
}
</style>
